import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Autocomplete from '../../components/Autocomplete';
import LinkedList from '../../components/LinkedList';

class DrugCkeck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      isSearchOpen: false
    });
  }

  handleSearchClick = () => {
    this.setState({
      isSearchOpen: true
    });
  };

  render() {
    const mostCommonSearchs = [
      {
        label: 'Citalopram',
        link:
            'results?searchParam=322621&searchLabel=Citalopram&searchType=active_ingredient_name'
      },
      {
        label: 'Clopidogrel',
        link:
            'results?searchParam=152211&searchLabel=Clopidogrel&searchType=active_ingredient_name'
      },
      {
        label: 'Codein',
        link:
            'results?searchParam=212901&searchLabel=Codein&searchType=active_ingredient_name'
      },
      {
        label: 'Warfarin',
        link:
            'results?searchParam=131031&searchLabel=Warfarin&searchType=active_ingredient_name'
      },
      {
        label: 'Simvastatin',
        link:
            'results?searchParam=621511&searchLabel=Simvastatin&searchType=active_ingredient_name'
      }
    ];

    return (
        <Container fluid className="drug-check p-5">
          <Row>
            <Col>
              <h5>
                <FormattedMessage id="search.intro" />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="10" className="drug-search-field">
              <Autocomplete />
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="5" className="common-search-items">
            <span>
              <FormattedMessage id="search.most_common_search" />
            </span>
              <LinkedList listItems={mostCommonSearchs} />
            </Col>
          </Row>
        </Container>
    );
  }
}

export default DrugCkeck;
