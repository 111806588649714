import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

const LoadingSpinner = props => (
  <div className="text-center py-5">
    <div className="loading-spinner my-5">
      <Spinner size="sm" color="primary" />
    </div>
    <FormattedMessage id={props.messageId} />
  </div>
);

LoadingSpinner.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default LoadingSpinner;
