import { SettingsService } from "@brightsolutionsgmbh/client-core";

/**
 * Current settings by host name
 */
const settingsByHost = {
  'default': {
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
    'site_logo': 'biologis.svg',
    'favicon': 'favicon-biologis.ico',
    'client_id': 'd9844058-4140-409d-ad4d-dbfde6b69bf3',
    'client_secret': 'e7eab7a7-95d4-47e8-b64f-dd2fd715b64a',
    'security_code': 'gim_biologis',
    'servicedesk_user': 'agentsmith',
    'servicedesk_password': 'Vouyeesh2sohngeivaikaed0Ue4eezaa',
  },
  'dm-webclient.dev.biologis.hosting.brightsolutions.de': {
    'domain': 'https://dm.dev.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'localhost': {
    'domain': 'https://dm.dev.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'node.dev.biologis-dm-web.de3.amazee.io': {
    'domain': 'https://dm.dev.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
    'node.qa.biologis-dm-web.de3.amazee.io': {
    'domain': 'https://nginx.qa.biologis-dm.de3.amazee.io',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'node.stage.biologis-dm-web.de3.amazee.io': {
    'domain': 'https://nginx.stage.biologis-dm.de3.amazee.io',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'dm-webclient.dev.biologis.hosting.brightsolutions.de': {
    'domain': 'https://dm.dev.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'dm-webclient.qa.biologis.hosting.brightsolutions.de': {
    'domain': 'https://dm.qa.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'dm-webclient.stage.biologis.hosting.brightsolutions.de': {
    'domain': 'https://dm.stage.biologis.hosting.brightsolutions.de',
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'devdm1.nauts.eu': {
    'site_name': 'devdm DM',
    'site_title': 'Pharmakogenetik RBK',
  },
  'biologis-gims-dm.biologisgroup.com': {
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'pharmasensor.net': {
    'site_name': 'bio.logis',
    'site_title': 'bio.logis - DM',
  },
  'pharmakogenetik.rbk.de': {
    'site_name': 'Robert-Bosch-Krankenhaus Stuttgart',
    'site_title': 'Pharmakogenetik RBK',
    'site_logo': 'logos.svg',
    'favicon': 'favicon-rbk.png',
    'security_code': 'KY6X',
  },
  'ikp-gims-dm.biologisgroup.com': {
    'site_name': 'Robert-Bosch-Krankenhaus Stuttgart',
    'site_title': 'Pharmakogenetik RBK',
    'site_logo': 'logos.svg',
    'favicon': 'favicon-rbk.png',
    'security_code': 'KY6X',
  },
  'uat-gims-dm.biologisgroup.com': {
    'site_name': 'UAT',
    'site_title': 'UAT - DM',
  },
  'uat2-gims-cms.biologisgroup.com': {
    'site_name': 'UAT2',
    'site_title': 'UAT2 - DM',
    'site_logo': 'logos.svg',
    'favicon': 'favicon-rbk.png',
    'security_code': 'KY6X',
  }
};

/**
 * Get the setting depending on the current host name.
 *
 * @param setting_name
 * @returns {*}
 */
export default new SettingsService(settingsByHost, window.location.hostname)
