import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import {flattenMessages} from '@brightsolutionsgmbh/client-core';
import LogIn from '../LogIn';
import LogOut from '../../components/LogOut';
import Header from './components/Header';
import RequestPasswordResetForm from '../RequestPasswordResetForm';
import PasswordResetForm from '../PasswordResetForm';
import GetApp from '../LogIn/components/GetApp';
import Information from '../Information';
import getDevice from '../../utils/device';
import {getLanguage} from '../../utils/language';
import '../../styles/App.scss';
import Settings from '../Settings';
import DrugCkeck from '../DrugCheck';
import Report from '../Report';
import SearchResults from '../SearchResults';
import Favorites from '../Favorites';
import DataTable from '../../components/DataTable';
import Gene from '../../components/Gene';
import Feedback from '../../components/Feedback';
import messages from '../../messages';
import Main  from "../Main";
import FirstLoginPasswordChangePrompt from '../FirstLoginPasswordChangePrompt';
import FirstLoginEmailSetPrompt from '../FirstLoginEmailSetPrompt';

const language = getLanguage();

export class App extends Component {
  render() {
    const lang = this.props.lang || language;
    const { isLoggedIn } = this.props;
    const localeData = messages[lang.split(/[-_]/)[0]];
    function ProtectedRoute({ component: Component, ...rest }) {
      if (isLoggedIn) {
        return <Component />;
      } else {
        return <Navigate to="/login" />;
      }
    }
    return (
    <IntlProvider locale={lang} messages={flattenMessages(localeData)}>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
        <>
          {!isLoggedIn && getDevice() && <GetApp />}
          <Header />
          <Routes>
            <Route
                path="login"
                element={<LogIn />}
            />
            <Route
                path="/reset-password"
                element={<RequestPasswordResetForm />}
            />
            <Route
                path="/set-new-password/:uid/:timestamp/:hash"
                element={<PasswordResetForm/>}
            />
            <Route path="/imprint" element={<Information />} />
            <Route path="/terms_and_conditions"  element={<Information />} />
            <Route path="/privacy_policy"  element={<Information />} />
            <Route path="/logout"  element={<LogOut />} />
            <Route
                path="/set-password"
                element={<ProtectedRoute component={FirstLoginPasswordChangePrompt} /> }
            />
            <Route
                path="/set-email"
                component={<ProtectedRoute component={FirstLoginEmailSetPrompt}/>}
            />
            <Route
                path="/"
                element={<ProtectedRoute component={Main}/>}>
              <Route path={'/settings'} element={<Settings />} />
              <Route
                  path="/"
                  element={<Navigate to="/search" />}
              />
              <Route path="/search" element={<DrugCkeck />} />
              <Route path="/search/results" element={<SearchResults />} />
              <Route path="/my-dna/reports" element={<Report />} />
              <Route
                  path="/my-dna/genes"
                  element={<DataTable tableKey="gene_table" />}
              />
              <Route
                  path="/my-dna/results"
                  element={<DataTable tableKey="result_table" />}
              />
              <Route
                  path="/my-dna/drugs"
                  element={<DataTable tableKey="active_ingredient_table" />}
              />
              <Route
                  path="/my-dna/genes/:id"
                  element={
                      <div className="p-4">
                        <Gene />
                      </div>
                  }
              />
              <Route path="/favorites" element={<Favorites />} />
              <Route
                  path="/info/support_info"
                  element={
                    <Information>
                      <Feedback />
                    </Information>
                  }
              />
              <Route path="/info/imprint" element={<Information />} />
              <Route
                  path="/info/terms_and_conditions"
                  element={<Information />}
              />
              <Route path="/info/privacy_policy" element={<Information />} />
              <Route path="*" element={<Navigate to="/" />} />

            </Route>


          </Routes>
        </>
      </BrowserRouter>
    </IntlProvider>
    );
  }
}

App.propTypes = {
  lang: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    lang: state.lang.lang,
    isLoggedIn: state.auth.isLoggedIn
  };
};

export default connect(mapStateToProps)(App);
