import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LogInForm } from '../../components/LogInForm';
import Footer from '../../components/Footer';
import LanguageSwitcher from '../../components/LangSwitcher';
import CookieConsent from './components/CookieConsent';
import RightSidebar from '../../components/RightSidebar';
import { apiService, stateService } from '../../services';
import { getLanguage } from '../../utils/language';
import settings from '../../utils/settings';
import {Col, Container, Row, UncontrolledAlert} from "reactstrap";

let headerLogos = require('../../images/' + settings.getSetting('site_logo'));

const LogIn = ({
                 logIn,
                 isLoggedIn,
                 error,
                 lang,
                 setLangLocally,
                 getCustomer,
                 getFile,
                 user,
                 isLoggingIn
               }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);


    const handleLogIn = async (username, password) => {
      try {
      const userAction = await logIn(username, password);
      if (userAction.ok) {
        const { user } = this.props;
        // set app's language to user's profile language (fallback to current local language)
        const userLang = (user.attributes || {}).preferred_langcode || lang;
        setLangLocally(userLang);
        // get customer logo
        const customerAction = await getCustomer(
            `/dm_customer/dm_customer/${user.relationships.field_customer.data.id}`
        );
        if (
            !customerAction.error &&
            (((customerAction.payload || {}).data || {}).relationships || {}).dm_logo
                .data &&
            (((customerAction.payload || {}).data || {}).relationships || {}).dm_logo
                .data.id
        ) {
            console.log(customerAction.payload.data.relationships.dm_logo.data.id)
          await getFile(
              customerAction.payload.data.relationships.dm_logo.data.id
          );
        }
      } else if (error) {
        setLoginError(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const username = new URLSearchParams(location.search).get('username');

  useEffect(() => {
    if (isLoggedIn && user && user.id) {
      navigate('/'); // Redirect to home page
    }
    const currentLang = lang || getLanguage();
      setLangLocally(currentLang);
  }, [isLoggedIn, user, navigate]);

  return (

      <Container fluid className="login text-center">
        <Row>
          <Col
              lg="10"
              className="d-flex flex-column justify-content-between align-items-center"
          >
              <LanguageSwitcher handleLangChange={lang => setLangLocally(lang)} />


              <div className="form-wrapper">
              <img
                  alt={settings.getSetting('site_name')}
                  className="logo my-2"
                  src={headerLogos}
              />
              <FormattedMessage id="logIn.welcome">
                {message => <h2 className="mb-4">{message}</h2>}
              </FormattedMessage>

              {loginError && (
                  <UncontrolledAlert color="danger">
                    <FormattedMessage id="logIn.loginError" />
                  </UncontrolledAlert>
              )}

              <LogInForm
                  logIn={handleLogIn}
                  username={username}
                  isLoading={isLoggingIn}
              />

              <Link to="/reset-password" className="btn btn-link my-4">
                <FormattedMessage id="logIn.passwordReset" />
              </Link>
            </div>
            <Footer />
            <CookieConsent />
          </Col>

          <RightSidebar />
        </Row>
      </Container>

  );
};

LogIn.propTypes = {
  logIn: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  userId: PropTypes.string,
  setLangLocally: PropTypes.func,
  lang: PropTypes.string.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggingIn: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
    lang: state.lang.lang,
    user: state.user.data,
    isLoggingIn: state.auth.isFetching
  };
};

export default connect(mapStateToProps, {
  logIn: apiService.logIn,
  setLangLocally: stateService.setLangLocally,
  getCustomer: apiService.getCustomer,
  getFile: apiService.getFile
})(LogIn);
